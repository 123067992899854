import React from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Col,
  Modal,
  Tab,
  Row,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { tambahUser, getAllClient } from "stores";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "stores";
import { editStatusUser } from "stores";
import { Dna } from "react-loader-spinner";

function ListUser() {
  const dispatch = useDispatch();
  const storeClient = useSelector((state) => state.clientReducer);
  const user = useSelector((state) => state.userReducer);
  const auth = useSelector((state) => state.authReducer);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const [pilihJabatan, setPilihJabatan] = React.useState(null);
  const [role, setRole] = React.useState("");
  const [listUser, setListUser] = React.useState([]);
  const [pilihClient, setPilihClient] = React.useState(null);
  const [idUser, setIdUser] = React.useState("");
  const [idClient, setIdClient] = React.useState("");
  const [modalUser, setModalUser] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalPenempatan, setModalPenempatan] = React.useState(false);
  const [nama, setNama] = React.useState("");
  const [nopeg, setNopeg] = React.useState("");
  const [listClient, setListClient] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const submitUser = () => {
    tambahUser({
      nama: nama,
      nopeg: nopeg,
      role: role,
      idClient: idClient,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            title: response.data.message,
            timer: 1000,
          });
          handleModalClose();
          getAllUser(dispatch);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("ERRORRRR", error);
      });
  };

  const handleModalClose = () => {
    setModalUser(false);
    setPilihClient(null);
    setPilihJabatan(null);
    setIdClient("");
    setPilihJabatan("");
  };

  const checkStatus = () => {
    if (status.value === undefined || status === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Status wajib diisi !",
      });
    } else {
      submitEditStatus();
    }
  };

  const submitEditStatus = () => {
    editStatusUser({
      idUser: idUser,
      status: status.value,
    })
      .then((response) => {
        console.log(response, ">>>>>>>>>>>>");
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          getAllUser(dispatch, { idClient: "" });
          setModalEdit(false);
          setStatus("");
        } else if (response.data.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
  };

  // const submitEdit = () => {
  //   Swal.fire({
  //     title: "Tunggu ...",
  //     didOpen() {
  //       Swal.showLoading();
  //     },
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //     allowEnterKey: false,
  //   });
  //   if (nama === "" || nopeg === "" || pilihClient === "") {
  //   } else {
  //     tambahUser({
  //       nama: nama,
  //       nopeg: nopeg,
  //       role: role,
  //       idClient: idClient,
  //     }).then((response) => {
  //       console.log(response);
  //       if (response === 200) {
  //         Swal.close();
  //         setModalPenempatan(false);
  //         setIdClient("");
  //         setPilihClient("");
  //       }
  //     });
  //   }
  // };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  React.useEffect(() => {
    let tmp =
      user.listUser &&
      user.listUser.map((val) => {
        return {
          ...val,
          action: (
            <>
              {val.role != "super admin" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Edit Status</Tooltip>}
                >
                  <Button
                    className="mr-0"
                    variant="success"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      setIdUser(val._id);
                      setModalEdit(!modalEdit);
                    }}
                  >
                    <i class="fas fa-edit"></i>
                  </Button>
                </OverlayTrigger>
              ) : (
                "-"
              )}{" "}
              {/* <Button
                className="mr-0"
                variant="info"
                style={{
                  marginTop: 5,
                }}
                onClick={() => {
                  setIdUser(val._id);
                  setModalEdit(!modalEdit);
                }}
              >
                Reset Password
              </Button> */}
            </>
          ),
        };
      });
    setListUser(tmp);
  }, [user.listUser]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeClient.listClient &&
      storeClient.listClient.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaClient,
        });
      });
    setListClient(tmp);
  }, [storeClient.listClient]);

  const getStatusUser = (statusUser) => {
    switch (statusUser) {
      case "tidak aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 80, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colUser = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Nama",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Username",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Jabatan",
      minWidth: "200px",
      center: true,
      selector: (row) => (row.role === "admin" ? "Admin" : "Client"),
    },
    {
      name: "BPD",
      minWidth: "200px",
      center: true,
      selector: (row) => (row.penempatan ? row.penempatan : "-"),
    },
    {
      name: "Status",
      minWidth: "150px",
      center: true,
      // selector: (row) => (row.status === "aktif" ? "Aktif" : "Tidak Aktif"),
      selector: (row) => row.status,
      cell: (row) => {
        const statusStyle = getStatusUser(row.status);
        const tampilanLabel = row.status === "aktif" ? "Aktif" : "Tidak Aktif";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "150px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    getAllUser(dispatch, { idClient: "" })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    getAllClient(dispatch);
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalEdit}
        onHide={() => {
          setStatus("");
          setModalEdit(!modalEdit);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4">Edit Status</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Status</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={status}
                    onChange={(selectedOption) => setStatus(selectedOption)}
                    // onChange={setStatus}
                    options={[
                      { value: "true", label: "Aktif" },
                      { value: "false", label: "Tidak Aktif" },
                    ]}
                    placeholder="Edit Status"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={checkStatus}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* <Modal
        size="lg"
        show={modalPenempatan}
        onHide={() => setModalPenempatan(!modalPenempatan)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4">Form Edit User</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label>Nama User</label>
                  <Form.Control
                    onChange={(e) => {
                      setNama(e.target.value);
                    }}
                    placeholder="Nama User"
                    type="text"
                  ></Form.Control>
                  <label>Username</label>
                  <Form.Control
                    onChange={(e) => {
                      setNopeg(e.target.value);
                    }}
                    placeholder="Username"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label>Pilih Client</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Client"
                    value={pilihClient}
                    onChange={(value) => {
                      setPilihClient(value);
                      setIdClient(value.value);
                    }}
                    options={listClient}
                    placeholder="Pilih Client"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitEdit}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal> */}

      <Tab.Content>
        <Card style={{ border: 0 }}>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col
              md={"4"}
              style={{
                marginTop: 20,
                marginRight: 25,
              }}
            >
              <Button
                className="btn-wd mr-1"
                // variant="primary"
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                  backgroundColor: "#1679AB",
                  border: 0,
                  fontWeight: "bold",
                }}
                onClick={() => setModalUser(!modalUser)}
              >
                Tambah User
              </Button>
            </Col>

            <Col md={"4"} style={{ marginTop: 25, marginRight: 25, order: 1 }}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="nc-icon nc-zoom-split icon-bold"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  style={{
                    paddingLeft: "10px",
                  }}
                  type="text"
                  value={searchText}
                  onChange={handleSearch}
                  placeholder="No Karyawan/Nama User"
                />
              </InputGroup>
            </Col>
          </Row>
        </Card>
      </Tab.Content>

      {isLoading ? (
        <div
          className="loader-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "30vh",
          }}
        >
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
          <p>Loading...</p>
        </div>
      ) : (
        <DataTable
          columns={colUser}
          data={listUser.filter(
            (row) =>
              row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
              row.nopeg.toLowerCase().includes(searchText.toLowerCase())
          )}
          // data={listUser}
          customStyles={customStyles}
          pagination
          paginationPerPage={itemsPerPage}
          onChangePage={handlePageChange}
        />
      )}
      <Modal
        size="lg"
        show={modalUser}
        onHide={() => {
          handleModalClose();
          setModalUser(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah User
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama User
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNama(e.target.value);
                    }}
                    placeholder="Nama User"
                    type="text"
                  ></Form.Control>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Username
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNopeg(e.target.value);
                    }}
                    placeholder="Username"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Jabatan
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Jabatan"
                    value={pilihJabatan}
                    onChange={(value) => {
                      setRole(value.value);
                      setPilihJabatan(value);
                    }}
                    options={[
                      {
                        value: "",
                        isDisabled: true,
                      },
                      { value: "admin", label: "Admin" },
                      { value: "client", label: "Client" },
                    ]}
                    placeholder="Pilih User"
                  />
                </Form.Group>
                {auth.role === "super admin" ? (
                  <Form.Group>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Pilih Client
                    </label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="Pilih Client"
                      value={pilihClient}
                      onChange={(value) => {
                        setPilihClient(value);
                        setIdClient(value.value);
                      }}
                      options={listClient}
                      placeholder="Pilih Client"
                    />
                  </Form.Group>
                ) : null}
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={submitUser}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default ListUser;
