import { baseAxios } from "../index";

export async function tambahSupervisor(data) {
  try {
    const response = await baseAxios.post(
      "webSupervisor/tambahSupervisor",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllSupervisor(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webSupervisor/getAllSupervisor?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALLSUPERVISOR", data: response.data });
    // console.log(response, "TTTTTTTTTTTTT");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function penempatanSupervisor(data) {
  try {
    const response = await baseAxios.post(
      "webSupervisor/penempatanSupervisor",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function tambahTargetSupervisor(data) {
  try {
    const response = await baseAxios.post(
      "webSupervisor/tambahTargetSupervisor",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllTargetSupervisor(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webSupervisor/getAllTargetSupervisor?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TARGET_SUPERVISOR", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function editTargetSupervisor(data) {
  try {
    const response = await baseAxios.patch(
      "webSupervisor/ubahTargetSupervisor",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function editStatusSupervisor(data) {
  try {
    const response = await baseAxios.patch(
      "webSupervisor/ubahStatusSupervisor",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    throw err.response.data;
  }
}
