import React from "react";
import { Button, Card, Form, Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";

function Step4() {
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = pengajuan.detailPengajuan;
  // console.log(valDetail, "LLLLLLLLLL");

  return (
    <>
      <Col md="12" style={{ marginTop: 20 }}>
        <Card.Header>
          <Card.Title
            as="h4"
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            infomrasi pinjaman
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Card style={{ border: 0 }}>
            <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    tujuan penggunaan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.stepEmpat.tujuanPenggunaan === "1"
                        ? "Konsumtif"
                        : "Produktif"}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    tenor
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.stepEmpat.tenor} Bulan
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    nominal permohonan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {`Rp ${valDetail.stepEmpat.nominalPermohonan.toLocaleString(
                        "id-ID"
                      )}`}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    nip
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.stepEmpat.nip}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    suku bunga
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.stepEmpat.sukuBunga}%
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Jenis Kredit
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.stepEmpat.jenisKredit === "51"
                        ? "ASN Payroll Non Guru"
                        : valDetail.stepEmpat.jenisKredit === "52"
                        ? "CASN Payroll Non Guru"
                        : valDetail.stepEmpat.jenisKredit === "53"
                        ? "ASN Non Payroll Non Guru"
                        : valDetail.stepEmpat.jenisKredit === "54"
                        ? "CASN Non Payroll Non Guru"
                        : valDetail.stepEmpat.jenisKredit === "55"
                        ? "Non ASN"
                        : valDetail.stepEmpat.jenisKredit === "56"
                        ? "Karyawan Swasta"
                        : valDetail.stepEmpat.jenisKredit === "57"
                        ? "ASN Payroll Guru"
                        : valDetail.stepEmpat.jenisKredit === "58"
                        ? "ASN Non Payroll Guru"
                        : valDetail.stepEmpat.jenisKredit === "59"
                        ? "CASN Payroll Guru"
                        : valDetail.stepEmpat.jenisKredit === "60"
                        ? "CASN Non Payroll Guru"
                        : valDetail.stepEmpat.jenisKredit === "61"
                        ? "Prapensiun ASN Payroll Guru"
                        : valDetail.stepEmpat.jenisKredit === "62"
                        ? "Prapensiun ASN Non Payroll Guru"
                        : valDetail.stepEmpat.jenisKredit === "63"
                        ? "Prapensiun ASN Payroll Non Guru"
                        : valDetail.stepEmpat.jenisKredit === "64"
                        ? "Prapensiun ASN Non Payroll Non Guru"
                        : valDetail.stepEmpat.jenisKredit === "65"
                        ? "Pensiunan ASN Payroll"
                        : valDetail.stepEmpat.jenisKredit === "66"
                        ? "Pensiunan ASN Non Payroll"
                        : valDetail.stepEmpat.jenisKredit === "67"
                        ? "KPR Umum ASN"
                        : valDetail.stepEmpat.jenisKredit === "1"
                        ? "Multiguna Produktif"
                        : valDetail.stepEmpat.jenisKredit === "2"
                        ? "Kredit Komersial Mikro Investasi"
                        : valDetail.stepEmpat.jenisKredit === "3"
                        ? "Kredit Komersial Mikro Modal Kerja"
                        : valDetail.stepEmpat.jenisKredit === "4"
                        ? "Kredit Komersial Kecil Investasi"
                        : valDetail.stepEmpat.jenisKredit === "5"
                        ? "Kredit Komersial Kecil Modal Kerja"
                        : valDetail.stepEmpat.jenisKredit === "6"
                        ? "Kredit Komersial Menengah Investasi"
                        : valDetail.stepEmpat.jenisKredit === "7"
                        ? "Kredit Komersial Menengah Modal Kerja"
                        : valDetail.stepEmpat.jenisKredit === "8"
                        ? "Kredit Komersial Besar Investasi"
                        : valDetail.stepEmpat.jenisKredit === "9"
                        ? "Kredit Komersial Besar Modal Kerja"
                        : valDetail.stepEmpat.jenisKredit === "10"
                        ? "Kredit Mikro Transaksi Khusus Investasi"
                        : "Kredit Mikro Transaksi Khusus Modal Kerja"}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    detail tujuan penggunaan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.stepEmpat.detailTujuanPenggunaan}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    status pinjaman
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.stepEmpat.statusPinjaman === "1"
                        ? "New"
                        : valDetail.stepEmpat.statusPinjaman === "2"
                        ? "Take Over"
                        : valDetail.stepEmpat.statusPinjaman === "3"
                        ? "Top Up"
                        : "Top Up"}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    cabang pencairan
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.lokasiPencairan.namaCabang}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    pembayaran gaji melalui
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.stepEmpat.pembayaranGajiMelalui === "1"
                        ? "PT BPD Kalteng"
                        : valDetail.stepEmpat.pembayaranGajiMelalui === "2"
                        ? "Instansi Dinas"
                        : valDetail.stepEmpat.pembayaranGajiMelalui === "3"
                        ? "Perusahaan"
                        : "Bank Lain"}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    alamat cabang
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {valDetail.lokasiPencairan.alamat}
                    </Label>
                  </li>
                </div>
              </div>
            </div>
          </Card>
        </Card.Body>
      </Col>

      <Card.Body>
        <Card.Header>
          <Card.Title
            as="h3"
            style={{
              color: "black",
              fontWeight: "bold",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            bukti form
            <hr />
          </Card.Title>
        </Card.Header>
        <div style={{ maxHeight: "650px", overflowY: "scroll" }}>
          <Row>
            {[
              {
                title: "form pernyataan debitur",
                imgSrc: valDetail.formPernyataanDebitur,
              },
              { title: "form bast", imgSrc: valDetail.formBast },
              { title: "form foto akad", imgSrc: valDetail.fotoAkad },
              {
                title: "form voucher pencairan",
                imgSrc: valDetail.voucherPencairan,
              },
            ].map((form, index) => (
              <Col md="6" key={index} style={{ marginBottom: "20px" }}>
                <Card style={{ height: "100%" }}>
                  <Card.Header>
                    <Card.Title
                      as="h3"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      {form.title}
                      <hr />
                    </Card.Title>
                  </Card.Header>
                  <Card.Body
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {form.imgSrc ? (
                      <img
                        src={form.imgSrc}
                        alt={form.title}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    ) : (
                      <>
                        <div style={{ marginBottom: "1rem" }}>
                          <div
                            style={{
                              backgroundColor: "#FEEFEE",
                              color: "#F75D59",
                              borderRadius: "8px",
                              padding: "10px 15px",
                              boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Maaf, gambar masih kosong
                          </div>
                        </div>
                        <img
                          src={require("assets/img/sorry.jpg").default}
                          alt="Sorry"
                          style={{ maxWidth: "50%", borderRadius: "5px" }}
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <Col md={{ span: 6, offset: 3 }} style={{ marginBottom: "20px" }}>
              <Card style={{ height: "100%" }}>
                <Card.Header>
                  <Card.Title
                    as="h3"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    form bukti pelunasan
                    <hr />
                  </Card.Title>
                </Card.Header>
                <Card.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {valDetail.buktiPelunasan ? (
                    <img
                      src={valDetail.buktiPelunasan}
                      alt="Form Bukti Pelunasan"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  ) : (
                    <>
                      <div style={{ marginBottom: "1rem" }}>
                        <div
                          style={{
                            backgroundColor: "#FEEFEE",
                            color: "#F75D59",
                            borderRadius: "8px",
                            padding: "10px 15px",
                            boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Maaf, gambar masih kosong
                        </div>
                      </div>
                      <img
                        src={require("assets/img/sorry.jpg").default}
                        alt="Sorry"
                        style={{ maxWidth: "50%", borderRadius: "5px" }}
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </>
  );
}
export default Step4;
