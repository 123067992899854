import React from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Col,
  Modal,
  Tab,
  Row,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { tambahMarketing } from "stores";
import { getAllMarketing } from "stores";
import { getAllClient } from "stores";
import { getAllCabang } from "stores";
import { penempatanMarketing } from "stores";
import { editStatusMarketing } from "stores";
import moment from "moment";
import { resetPassword } from "stores";
import { Dna } from "react-loader-spinner";

function ListMarketing() {
  const dispatch = useDispatch();
  const marketing = useSelector((state) => state.marketingReducer);
  const storeClient = useSelector((state) => state.clientReducer);
  const storeCabang = useSelector((state) => state.cabangReducer);
  const auth = useSelector((state) => state.authReducer);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const [salesMarketing, setSalesMarketing] = React.useState(false);
  const [modalPenempatan, setModalPenempatan] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [modalUbahPenempatan, setModalUbahPenempatan] = React.useState(false);
  const [pilihClient, setPilihClient] = React.useState(null);
  const [pilihRole, setPilihRole] = React.useState(null);
  const [role, setRole] = React.useState("");
  const [nik, setNik] = React.useState("");
  const [nama, setNama] = React.useState("");
  const [nopeg, setNopeg] = React.useState("");
  const [listClient, setListClient] = React.useState([]);
  const [listMarketing, setListMarketing] = React.useState([]);
  const [idClient, setIdClient] = React.useState("");
  const [pilihCabang, setPilihCabang] = React.useState(null);
  const [listCabang, setListCabang] = React.useState([]);
  const [idCabang, setIdCabang] = React.useState(null);
  const [idMarketing, setIdMarketing] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleNikChange = (e) => {
    const inputNik = e.target.value;
    const formattedNik = inputNik.slice(0, 16);
    setNik(formattedNik);
  };

  const remainingDigits = 16 - nik.length;

  const submitMarketing = () => {
    if (nik.length < 16) {
      Swal.fire({
        title: "NIK harus 16 digit !!",
        icon: "warning",
      });
      return;
    }
    tambahMarketing({
      nik: nik,
      nama: nama,
      nopeg: nopeg,
      role: role,
      idClient: idClient,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          handleModalClose();
          getAllMarketing(dispatch, { idClient: "" });
          // setIdClient("");
          // setPilihClient("");
        } else if (response.data.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("ERRORRRR", error);
      });
  };

  const checkStatus = () => {
    if (status.value === undefined || status === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Status wajib diisi !",
      });
    } else {
      submitEditStatus();
    }
  };

  const submitEditStatus = () => {
    console.log(status.value);
    editStatusMarketing({
      idMarketing: idMarketing,
      status: status.value,
    })
      .then((response) => {
        console.log(response, ">>>>>>>.");
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          getAllMarketing(dispatch, { idClient: "" });
          setModalEdit(false);
          setStatus("");
        } else if (response.data.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
  };

  const submitPenempatan = () => {
    if (pilihCabang === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pemilihan Cabang tidak boleh kosong",
      });
    } else {
      penempatanMarketing({
        idMarketing: idMarketing,
        idCabang: idCabang,
      }).then((response) => {
        console.log(response);
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          handleModalClose();
          setIdCabang("");
          setPilihCabang("");
          getAllMarketing(dispatch, { idClient: "" });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      });
    }
  };

  const submitUbahPenempatan = () => {
    if (pilihCabang === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pemilihan Cabang tidak boleh kosong",
      });
    } else {
      penempatanMarketing({
        idMarketing: idMarketing,
        idCabang: idCabang,
      }).then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          handleModalClose();
          setIdCabang(null);
          setPilihCabang(null);
          getAllMarketing(dispatch, { idClient: "" });
        }
      });
    }
  };

  const handleModalClose = () => {
    setPilihCabang(null);
    setIdCabang(null);
    setPilihRole(null);
    setRole("");
    setPilihClient(null);
    setSalesMarketing(false);
    setModalPenempatan(false);
    setModalUbahPenempatan(false);
    setNik("");
    setNopeg("");
    setNama("");
  };

  React.useEffect(() => {
    let tmp =
      marketing.listMarketing &&
      marketing.listMarketing.map((val) => {
        const ubahPenempatan = val.penempatan != "";
        return {
          ...val,
          action: (
            <>
              {" "}
              {ubahPenempatan ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Ubah Penempatan</Tooltip>}
                >
                  <Button
                    className="mr-1"
                    variant="warning"
                    style={{
                      marginTop: 5,
                      padding: "7px 16px",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      setIdMarketing(val._id);
                      getAllCabang(dispatch, { idClient: val.idClient });
                      setModalUbahPenempatan(!modalUbahPenempatan);
                    }}
                  >
                    <i class="fas fa-map-marked-alt"></i>
                  </Button>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Penempatan Marketing</Tooltip>}
                >
                  <Button
                    disabled={val.status === "aktif" ? false : true}
                    className="mr-0"
                    variant="info"
                    style={{
                      marginTop: 5,
                      cursor:
                        val.status === "tidak aktif"
                          ? "not-allowed"
                          : "pointer",
                      backgroundColor:
                        val.status === "tidak aktif" ? "grey" : "#447DF7",
                      border: 0,
                      padding: "7px 16px",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      setIdMarketing(val._id);
                      getAllCabang(dispatch, { idClient: val.idClient });
                      setModalPenempatan(!modalPenempatan);
                    }}
                  >
                    <i class="fas fa-map-marked-alt"></i>
                  </Button>
                </OverlayTrigger>
              )}{" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Edit Status</Tooltip>}
              >
                <Button
                  className="mr-1"
                  variant="success"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setIdMarketing(val._id);
                    setModalEdit(!modalEdit);
                  }}
                >
                  <i class="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>{" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Reset Password</Tooltip>}
              >
                <Button
                  className="mr-0"
                  variant="danger"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={async () => {
                    const result = await Swal.fire({
                      title: "Reset Password",
                      text: `Apakah Anda yakin ingin mereset password pengguna ${val.nama}?`,
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ya, Reset Password!",
                    });

                    if (result.isConfirmed) {
                      try {
                        const response = await resetPassword({
                          idUser: val._id,
                          role: val.role,
                        });

                        Swal.fire({
                          title: "Sukses",
                          text: response.data.message,
                          icon: "success",
                        });
                      } catch (error) {
                        Swal.fire({
                          title: "Error",
                          text: error.response.data.message,
                          icon: "error",
                        });
                      }
                    }
                  }}
                >
                  <i class="fas fa-redo-alt"></i>
                </Button>
              </OverlayTrigger>
              {/* {" "}
              <Button
                className="mr-0"
                variant="info"
                style={{ marginTop: 5 }}
                onClick={() => {
                  setModalDetail(!modalDetail);
                  console.log(val);
                }}
              >
                Detail
              </Button> */}
            </>
          ),
        };
      });
    setListMarketing(tmp);
  }, [marketing.listMarketing]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeCabang.listCabang &&
      storeCabang.listCabang.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaCabang,
        });
      });

    setListCabang(tmp);
  }, [storeCabang.listCabang]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeClient.listClient &&
      storeClient.listClient.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaClient,
        });
      });

    setListClient(tmp);
  }, [storeClient.listClient]);

  const getStatusUser = (statusUser) => {
    switch (statusUser) {
      case "tidak aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "aktif":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colSalesMarketing = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Kode User",
      minWidth: "200px",
      center: true,
      selector: (row) => row.kodeUser,
    },
    {
      name: "No Karyawan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Nama",
      minWidth: "200px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Jabatan",
      minWidth: "200px",
      center: true,
      selector: (row) =>
        row.role === "marketing" ? "Sales Marketing" : "Direct Sales Marketing",
    },
    {
      name: "BPD",
      minWidth: "200px",
      center: true,
      omit: auth.role === "super admin" ? false : true,
      selector: (row) =>
        row.penempatan.length === 0 ? (
          <div style={{ fontWeight: "bold" }}>Belum Penempatan</div>
        ) : (
          row.penempatan[0].namaClient
        ),
    },
    {
      name: "Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) =>
        row.penempatan.length === 0 ? (
          <div style={{ fontWeight: "bold" }}>Belum Penempatan</div>
        ) : (
          row.penempatan[0].namaCabang
        ),
    },
    {
      name: "Login Terakhir",
      minWidth: "300px",
      center: true,
      selector: (row) =>
        !row.loginDate ? "-" : moment(row.loginDate).format("DD-MM-YYYY hh:mm"),
    },
    {
      name: "Status",
      minWidth: "150px",
      center: true,
      // selector: (row) => (row.status === "aktif" ? "Aktif" : "Tidak Aktif"),
      selector: (row) => row.status,
      cell: (row) => {
        const statusStyle = getStatusUser(row.status);
        const tampilanLabel = row.status === "aktif" ? "Aktif" : "Tidak Aktif";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "250px",
      omit: auth.role === "super admin" || auth.role === "admin" ? false : true,
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    getAllMarketing(dispatch, { idClient: "" })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    getAllClient(dispatch);
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalUbahPenempatan}
        onHide={() => {
          handleModalClose();
          setModalUbahPenempatan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4" style={{ color: "black" }}>
                Form Ubah Penempatan Marketing
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Cabang</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Cabang"
                    value={pilihCabang}
                    onChange={(value) => {
                      setPilihCabang(value);
                      setIdCabang(value.value);
                    }}
                    options={listCabang}
                    placeholder="Pilih Cabang"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitUbahPenempatan}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalPenempatan}
        onHide={() => {
          handleModalClose();
          setModalPenempatan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4" style={{ color: "black" }}>
                Form Penempatan Marketing
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Cabang</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Cabang"
                    value={pilihCabang}
                    onChange={(value) => {
                      setPilihCabang(value);
                      setIdCabang(value.value);
                    }}
                    options={listCabang}
                    placeholder="Pilih Cabang"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitPenempatan}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalEdit}
        onHide={() => {
          setStatus("");
          setModalEdit(!modalEdit);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title as="h4">Edit Status</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Status</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={status}
                    onChange={(selectedOption) => setStatus(selectedOption)}
                    // onChange={setStatus}
                    options={[
                      { value: "true", label: "Aktif" },
                      { value: "false", label: "Tidak Aktif" },
                    ]}
                    placeholder="Edit Status"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={checkStatus}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Tab.Content>
        <Card style={{ border: 0 }}>
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Col
              md={"4"}
              style={{
                marginTop: 20,
                marginRight: 25,
              }}
            >
              {auth.role === "super admin" || auth.role === "admin" ? (
                <Button
                  className="btn-wd mr-1"
                  // variant="primary"
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    backgroundColor: "#1679AB",
                    border: 0,
                    fontWeight: "bold",
                  }}
                  onClick={() => setSalesMarketing(!salesMarketing)}
                >
                  Tambah Marketing
                </Button>
              ) : null}
            </Col>

            <Col md={"4"} style={{ marginTop: 25, marginRight: 25, order: 1 }}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="nc-icon nc-zoom-split icon-bold"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  style={{
                    paddingLeft: "10px",
                  }}
                  type="text"
                  value={searchText}
                  onChange={handleSearch}
                  placeholder="No Karyawan/Nama Marketing"
                />
              </InputGroup>
            </Col>
          </Row>
        </Card>
      </Tab.Content>

      {isLoading ? (
        <div
          className="loader-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "30vh",
          }}
        >
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
          <p>Loading...</p>
        </div>
      ) : (
        <DataTable
          columns={colSalesMarketing}
          data={listMarketing.filter(
            (row) =>
              row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
              row.nopeg.toLowerCase().includes(searchText.toLowerCase())
            // ||
            // row.penempatan[0].namaCabang
            //   .toLowerCase()
            //   .includes(searchText.toLowerCase())
          )}
          // data={listMarketing}
          customStyles={customStyles}
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          paginationPerPage={itemsPerPage}
          onChangePage={handlePageChange}
        />
      )}
      <Modal
        size="lg"
        show={salesMarketing}
        onHide={() => {
          handleModalClose();
          setSalesMarketing(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah Marketing
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    NIK
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Control
                      onChange={handleNikChange}
                      value={nik}
                      type="text"
                      style={{ flex: 1 }}
                      placeholder="NIK"
                    />
                    <div
                      style={{
                        marginLeft: "10px",
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      {remainingDigits} digit
                    </div>
                  </div>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNama(e.target.value);
                    }}
                    placeholder="Nama"
                    type="text"
                  ></Form.Control>
                  <label
                    style={{
                      marginTop: 10,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    No Karyawan
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNopeg(e.target.value);
                    }}
                    placeholder="No Karyawan"
                    type="text"
                  ></Form.Control>
                  {/* </Form.Group>
                <Form.Group> */}
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Role
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Role"
                    value={pilihRole}
                    // onChange={(selectedOption) => setStatus(selectedOption)}
                    onChange={(value) => {
                      setPilihRole(value);
                      setRole(value.value);
                      console.log(value.value);
                    }}
                    options={[
                      { value: "marketing", label: "Sales Marketing" },
                      { value: "direct marketing", label: "Direct Sales" },
                    ]}
                    placeholder="Pilih Role"
                  />
                </Form.Group>
                {auth.role === "super admin" ? (
                  <Form.Group>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Pilih Client
                    </label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="Pilih Client"
                      value={pilihClient}
                      onChange={(value) => {
                        setPilihClient(value);
                        setIdClient(value.value);
                      }}
                      options={listClient}
                      placeholder="Pilih Client"
                    />
                  </Form.Group>
                ) : null}
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={submitMarketing}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
    </>
  );
}
export default ListMarketing;
