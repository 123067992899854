import { baseAxios } from "../index";

export async function tambahKorwil(data) {
  try {
    const response = await baseAxios.post("webKorwil/tambahKorwil", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllKorwil(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webKorwil/getAllKorwil?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALLKORWIL", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function penempatanKorwil(data) {
  try {
    const response = await baseAxios.post("webKorwil/penempatanKorwil", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function tambahTargetKorwil(data) {
  try {
    const response = await baseAxios.post(
      "webKorwil/tambahTargetKorwil",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllTargetKorwil(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webKorwil/getAllTargetKorwil?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TARGET_KORWIL", data: response.data });
    // console.log(response, "TARGETTTTTTTTTTOSSSS");
  } catch (err) {
    return err.response;
  }
}

export async function editTargetKorwil(data) {
  try {
    const response = await baseAxios.patch("webKorwil/ubahTargetKorwil", data, {
      headers: { token: localStorage.getItem("token") },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function editStatusKorwil(data) {
  try {
    const response = await baseAxios.patch("webKorwil/ubahStatusKorwil", data, {
      headers: { token: localStorage.getItem("token") },
    });

    return response;
  } catch (err) {
    throw err.response.data;
  }
}
